<template>
  <BookingPosition
    displayedCard="rejected"
  >
    <div class="wrapper w100" v-if="this.position">
      <div class="navbar d-flex">
        <div>
          <b-link :to="{ name: 'positions-rejected' }">&lt; Volver</b-link>
        </div>
        <div>
          <b-button
            variant="secondary"
            :disabled="!previous"
            :to="previous"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-left.svg')">
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!next"
            :to="next"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-right.svg')">
          </b-button>
        </div>
      </div>
      <DisplayBookingPosition
        :position="position"
      />
    </div>
  </BookingPosition>
</template>
<script>
import BookingPosition from '@/views/BookingPosition.vue'
import DisplayBookingPosition from '@/components/BookingPosition/DisplayBookingPosition.vue'

export default {
  name: 'BookingPositionRejected',
  components: { BookingPosition, DisplayBookingPosition },
  data () {
    return {
      positions: [],
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    position () {
      return this.positions[this.getPositionIndex()]
    },
    next () {
      const index = 1 + this.getPositionIndex()
      if (this.positions.length === index) {
        return null
      }
      return {
        name: 'positions-rejected-position',
        params: {
          id: this.positions[index].getId
        }
      }
    },
    previous () {
      const index = this.getPositionIndex()
      if (index === 0) {
        return null
      }
      return {
        name: 'positions-rejected-position',
        params: {
          id: this.positions[index - 1].getId
        }
      }
    }
  },
  methods: {
    getPositionIndex () {
      return this.positions.findIndex(position => position.getId === this.$route.params.id)
    },
    loadData () {
      this.$store.dispatch('getPositionRejected')
        .then((res) => {
          this.positions = res
        })
    }
  }
}
</script>
<style lang="less" scoped>
  @import '../style/colors.less';
  .position-container {
    .wrapper {
      .navbar {
        padding-bottom: 1rem;
        padding-top: 0rem;
        .btn.btn-secondary {
          padding: 0.15rem 1rem;
          margin-left: 0.4rem;
        }
        a {
          color: #ffffff;
        }
      }
    }
  }
</style>
